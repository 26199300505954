/*! jQuery Mobile v1.3.2 | Copyright 2010, 2013 jQuery Foundation, Inc. | jquery.org/license */
(function(e, t, n) {
  typeof define == "function" && define.amd ? define(["jquery"], function(r) {
    return n(r, e, t), r.mobile
  }) : n(e.jQuery, e, t)
})(this, document, function(e, t, n, r) {
  (function(e, t, n, r) {
    function x(e) {
      while (e && typeof e.originalEvent != "undefined") e = e.originalEvent;
      return e
    }

    function T(t, n) {
      var i = t.type,
        s, o, a, l, c, h, p, d, v;
      t = e.Event(t), t.type = n, s = t.originalEvent, o = e.event.props, i.search(/^(mouse|click)/) > -1 && (o = f);
      if (s)
        for (p = o.length, l; p;) l = o[--p], t[l] = s[l];
      i.search(/mouse(down|up)|click/) > -1 && !t.which && (t.which = 1);
      if (i.search(/^touch/) !== -1) {
        a = x(s), i = a.touches, c = a.changedTouches, h = i && i.length ? i[0] : c && c.length ? c[0] : r;
        if (h)
          for (d = 0, v = u.length; d < v; d++) l = u[d], t[l] = h[l]
      }
      return t
    }

    function N(t) {
      var n = {},
        r, s;
      while (t) {
        r = e.data(t, i);
        for (s in r) r[s] && (n[s] = n.hasVirtualBinding = !0);
        t = t.parentNode
      }
      return n
    }

    function C(t, n) {
      var r;
      while (t) {
        r = e.data(t, i);
        if (r && (!n || r[n])) return t;
        t = t.parentNode
      }
      return null
    }

    function k() {
      g = !1
    }

    function L() {
      g = !0
    }

    function A() {
      E = 0, v.length = 0, m = !1, L()
    }

    function O() {
      k()
    }

    function M() {
      _(), c = setTimeout(function() {
        c = 0, A()
      }, e.vmouse.resetTimerDuration)
    }

    function _() {
      c && (clearTimeout(c), c = 0)
    }

    function D(t, n, r) {
      var i;
      if (r && r[t] || !r && C(n.target, t)) i = T(n, t), e(n.target).trigger(i);
      return i
    }

    function P(t) {
      var n = e.data(t.target, s);
      if (!m && (!E || E !== n)) {
        var r = D("v" + t.type, t);
        r && (r.isDefaultPrevented() && t.preventDefault(), r.isPropagationStopped() && t.stopPropagation(), r.isImmediatePropagationStopped() && t.stopImmediatePropagation())
      }
    }

    function H(t) {
      var n = x(t).touches,
        r, i;
      if (n && n.length === 1) {
        r = t.target, i = N(r);
        if (i.hasVirtualBinding) {
          E = w++, e.data(r, s, E), _(), O(), d = !1;
          var o = x(t).touches[0];
          h = o.pageX, p = o.pageY, D("vmouseover", t, i), D("vmousedown", t, i)
        }
      }
    }

    function B(e) {
      if (g) return;
      d || D("vmousecancel", e, N(e.target)), d = !0, M()
    }

    function j(t) {
      if (g) return;
      var n = x(t).touches[0],
        r = d,
        i = e.vmouse.moveDistanceThreshold,
        s = N(t.target);
      d = d || Math.abs(n.pageX - h) > i || Math.abs(n.pageY - p) > i, d && !r && D("vmousecancel", t, s), D("vmousemove", t, s), M()
    }

    function F(e) {
      if (g) return;
      L();
      var t = N(e.target),
        n;
      D("vmouseup", e, t);
      if (!d) {
        var r = D("vclick", e, t);
        r && r.isDefaultPrevented() && (n = x(e).changedTouches[0], v.push({
          touchID: E,
          x: n.clientX,
          y: n.clientY
        }), m = !0)
      }
      D("vmouseout", e, t), d = !1, M()
    }

    function I(t) {
      var n = e.data(t, i),
        r;
      if (n)
        for (r in n)
          if (n[r]) return !0;
      return !1
    }

    function q() {}

    function R(t) {
      var n = t.substr(1);
      return {
        setup: function(r, s) {
          I(this) || e.data(this, i, {});
          var o = e.data(this, i);
          o[t] = !0, l[t] = (l[t] || 0) + 1, l[t] === 1 && b.bind(n, P), e(this).bind(n, q), y && (l.touchstart = (l.touchstart || 0) + 1, l.touchstart === 1 && b.bind("touchstart", H).bind("touchend", F).bind("touchmove", j).bind("scroll", B))
        },
        teardown: function(r, s) {
          --l[t], l[t] || b.unbind(n, P), y && (--l.touchstart, l.touchstart || b.unbind("touchstart", H).unbind("touchmove", j).unbind("touchend", F).unbind("scroll", B));
          var o = e(this),
            u = e.data(this, i);
          u && (u[t] = !1), o.unbind(n, q), I(this) || o.removeData(i)
        }
      }
    }
    var i = "virtualMouseBindings",
      s = "virtualTouchID",
      o = "vmouseover vmousedown vmousemove vmouseup vclick vmouseout vmousecancel".split(" "),
      u = "clientX clientY pageX pageY screenX screenY".split(" "),
      a = e.event.mouseHooks ? e.event.mouseHooks.props : [],
      f = e.event.props.concat(a),
      l = {},
      c = 0,
      h = 0,
      p = 0,
      d = !1,
      v = [],
      m = !1,
      g = !1,
      y = "addEventListener" in n,
      b = e(n),
      w = 1,
      E = 0,
      S;
    e.vmouse = {
      moveDistanceThreshold: 10,
      clickDistanceThreshold: 10,
      resetTimerDuration: 1500
    };
    for (var U = 0; U < o.length; U++) e.event.special[o[U]] = R(o[U]);
    y && n.addEventListener("click", function(t) {
      var n = v.length,
        r = t.target,
        i, o, u, a, f, l;
      if (n) {
        i = t.clientX, o = t.clientY, S = e.vmouse.clickDistanceThreshold, u = r;
        while (u) {
          for (a = 0; a < n; a++) {
            f = v[a], l = 0;
            if (u === r && Math.abs(f.x - i) < S && Math.abs(f.y - o) < S || e.data(u, s) === f.touchID) {
              t.preventDefault(), t.stopPropagation();
              return
            }
          }
          u = u.parentNode
        }
      }
    }, !0)
  })(e, t, n),
  function(e) {
    e.mobile = {}
  }(e),
  function(e, t) {
    var r = {
      touch: "ontouchend" in n
    };
    e.mobile.support = e.mobile.support || {}, e.extend(e.support, r), e.extend(e.mobile.support, r)
  }(e),
  function(e, t, r) {
    function l(t, n, r) {
      var i = r.type;
      r.type = n, e.event.dispatch.call(t, r), r.type = i
    }
    var i = e(n);
    e.each("touchstart touchmove touchend tap taphold swipe swipeleft swiperight scrollstart scrollstop".split(" "), function(t, n) {
      e.fn[n] = function(e) {
        return e ? this.bind(n, e) : this.trigger(n)
      }, e.attrFn && (e.attrFn[n] = !0)
    });
    var s = e.mobile.support.touch,
      o = "touchmove scroll",
      u = s ? "touchstart" : "mousedown",
      a = s ? "touchend" : "mouseup",
      f = s ? "touchmove" : "mousemove";
    e.event.special.scrollstart = {
      enabled: !0,
      setup: function() {
        function s(e, n) {
          r = n, l(t, r ? "scrollstart" : "scrollstop", e)
        }
        var t = this,
          n = e(t),
          r, i;
        n.bind(o, function(t) {
          if (!e.event.special.scrollstart.enabled) return;
          r || s(t, !0), clearTimeout(i), i = setTimeout(function() {
            s(t, !1)
          }, 50)
        })
      }
    }, e.event.special.tap = {
      tapholdThreshold: 750,
      setup: function() {
        var t = this,
          n = e(t);
        n.bind("vmousedown", function(r) {
          function a() {
            clearTimeout(u)
          }

          function f() {
            a(), n.unbind("vclick", c).unbind("vmouseup", a), i.unbind("vmousecancel", f)
          }

          function c(e) {
            f(), s === e.target && l(t, "tap", e)
          }
          if (r.which && r.which !== 1) return !1;
          var s = r.target,
            o = r.originalEvent,
            u;
          n.bind("vmouseup", a).bind("vclick", c), i.bind("vmousecancel", f), u = setTimeout(function() {
            l(t, "taphold", e.Event("taphold", {
              target: s
            }))
          }, e.event.special.tap.tapholdThreshold)
        })
      }
    }, e.event.special.swipe = {
      scrollSupressionThreshold: 30,
      durationThreshold: 1e3,
      horizontalDistanceThreshold: 30,
      verticalDistanceThreshold: 75,
      start: function(t) {
        var n = t.originalEvent.touches ? t.originalEvent.touches[0] : t;
        return {
          time: (new Date).getTime(),
          coords: [n.pageX, n.pageY],
          origin: e(t.target)
        }
      },
      stop: function(e) {
        var t = e.originalEvent.touches ? e.originalEvent.touches[0] : e;
        return {
          time: (new Date).getTime(),
          coords: [t.pageX, t.pageY]
        }
      },
      handleSwipe: function(t, n) {
        n.time - t.time < e.event.special.swipe.durationThreshold && Math.abs(t.coords[0] - n.coords[0]) > e.event.special.swipe.horizontalDistanceThreshold && Math.abs(t.coords[1] - n.coords[1]) < e.event.special.swipe.verticalDistanceThreshold && t.origin.trigger("swipe").trigger(t.coords[0] > n.coords[0] ? "swipeleft" : "swiperight")
      },
      setup: function() {
        var t = this,
          n = e(t);
        n.bind(u, function(t) {
          function o(t) {
            if (!i) return;
            s = e.event.special.swipe.stop(t), Math.abs(i.coords[0] - s.coords[0]) > e.event.special.swipe.scrollSupressionThreshold && t.preventDefault()
          }
          var i = e.event.special.swipe.start(t),
            s;
          n.bind(f, o).one(a, function() {
            n.unbind(f, o), i && s && e.event.special.swipe.handleSwipe(i, s), i = s = r
          })
        })
      }
    }, e.each({
      scrollstop: "scrollstart",
      taphold: "tap",
      swipeleft: "swipe",
      swiperight: "swipe"
    }, function(t, n) {
      e.event.special[t] = {
        setup: function() {
          e(this).bind(n, e.noop)
        }
      }
    })
  }(e, this)
});

$(document).ready(function() {

  var $mainBackGround = $(".js_background");
  var $carousel = $("#mainCarousel");
  var $searchForm = $(".js_searchString");

  //add bg images
  var allImagesDivs = [];
  $("[data-bg-img]").each(function() {
    if ($(this).data("bg-img-slide")) {
      allImagesDivs.push('<div id=' + $(this).data("bg-img-slide") + ' class="gallery-bg-slide js_background_slider" style="background-image: ' + $(this).data("bg-img") + ';"></div>')
    }
  });
  $mainBackGround.append(allImagesDivs);
  $mainBackGround.find("#slide0").addClass('active');
  var $mainBackGroundChilds = $(".js_background_slider");

  //add swipe to gallery
  if ($carousel.length != 0) {
    $carousel.swiperight(function() {
      $(this).carousel('prev');
    });
    $carousel.swipeleft(function() {
      $(this).carousel('next');
    });
  }
  //off drag image in gallery for normal swipe
  makeUnselectable($(".carousel-inner img"));

  //add carousel events
  $carousel.bind('slide.bs.carousel', function(event) {
    var bgId = $(event.relatedTarget).data("bg-img-slide");
    $mainBackGroundChilds.removeClass("active");
    $mainBackGround.find("#" + bgId).addClass("active");
  });

  //add hover on coloredBox for background
  var $hoverFilter = $(".js_hoverFilter");
  $(".coloredBoxs-item").hover(function() {
    $hoverFilter.addClass($(this).data("change-bg-to"));
  }, function() {
    $hoverFilter.removeClass($(this).data("change-bg-to"));
  })


  //init calendar
  if ($("#eventCalendar").length != 0) {
    $.get('/api/events.json').done(function(data){
      $("#eventCalendar").eventCalendar({
        jsonData: data, // link to events json
        jsonDateFormat: 'human',
        showDescription: true,
        dateFormat: 'DD MMMM, dddd',
        locales: {
          locale: "ru",
          monthNames: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь",
            "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"
          ],
          dayNames: ['воскресенье', 'понедельник', 'вторник', 'среда',
            'четверг', 'пятница', 'суббота'
          ],
          dayNamesShort: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
          txt_noEvents: "Нет событий",
          txt_SpecificEvents_prev: "",
          txt_SpecificEvents_after: "события:",
          txt_next: "Далее",
          txt_prev: "Назад",
          txt_NextEvents: "",
          txt_GoToEventUrl: "Открыть событие",
          moment: {
            "months": ["Января", "Февраля", "Марта", "Апреля", "Мая", "Июня", "Июля", "Августа", "Сентября", "Октября", "Ноября", "Декабря"],
            "monthsShort": ["Янв", "Фев", "Мар", "Апр", "Май", "Июнь",
              "Июль", "Авг", "Сен", "Окт", "Ноя", "Дек"
            ],
            "weekdays": ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'Суббота'],
            "weekdaysShort": ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
            "weekdaysMin": ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
            "longDateFormat": {
              "LT": "H:mm",
              "LTS": "LT:ss",
              "L": "DD/MM/YYYY",
              "LL": "D [] MMMM [] YYYY",
              "LLL": "D [] MMMM [] YYYY LT",
              "LLLL": "dddd, D [] MMMM [] YYYY LT"
            },
            "week": {
              "dow": 1,
              "doy": 4
            }
          }
        }
      });
    })

  }

  //search open/close
  $('.js_openSearch,.inSearchSearchIcon').on("click", function() {
    $('.mobileIcons').toggleClass('hidden');
    $searchForm.toggleClass("open");
    $('.js_closedWhenSearch').toggleClass('closedWhenSearch');
    if ($searchForm.hasClass('open')) {
      $searchForm.find(".ya-site-form__input-text")[0].focus();
    }
  })
  $('.js_closeSearchIcon').on("click", function() {
    $searchForm.find(".ya-site-form__input-text")[0].value = '';
    $(this).hide();
  });
  // $('.js_closeSearchIcon').show();
  $('body').on('keyup', '.ya-site-form__input-text', function() {
    if ($(this).val()) {
      $('.js_closeSearchIcon').show();
    } else {
      $('.js_closeSearchIcon').hide();
    }
  })

  var $hamburgerMenu = $('.js_hamburgerOpenIco');
  var $searchOpenIco = $('.js_searchOpenIco');
  var $profile = $('.profile');
  var $dropDowns = $('.dropDown');

  var $closeH = $('.js_closeHamburger');
  var $hamburger = $('.hamburger');

  function selectMobileMenu(that) {
    $dropDowns.addClass('hiddenOnMobile');

    var $target = $($(that).data('show-menu'));
    $target.each(function() {
      $(this).removeClass('hiddenOnMobile');
    })
    $hamburger.removeClass('closedOnMobile');
    $hamburger.parent().removeClass('hiddenOnMobile');
    $closeH.removeClass('hiddenOnMobile');

    $searchOpenIco.addClass('hiddenOnMobile');
    $hamburgerMenu.addClass('hiddenOnMobile');
    $profile.addClass('hiddenOnMobile');
  }

  function closeMobileMenu() {
    $searchOpenIco.removeClass('hiddenOnMobile');
    $hamburgerMenu.removeClass('hiddenOnMobile');
    $profile.removeClass('hiddenOnMobile');
    $closeH.addClass('hiddenOnMobile');
    $dropDowns.addClass('hiddenOnMobile');
    $hamburger.addClass('closedOnMobile');
    $hamburger.parent().addClass('hiddenOnMobile');
  }

  $hamburgerMenu.on('click', function() {
    selectMobileMenu(this);
  })
  $searchOpenIco.on('click', function() {
    $('.js_openSearch').trigger('click');
    $searchForm.find(".ya-site-form__input-text")[0].focus();
  })
  $closeH.on('click', function() {
    closeMobileMenu();
  })
  var $yandexSearchModal = $('#yandexSearchModal');

  // $('#searchForm').submit(function(event){
  //     event.preventDefault();
  //     beginSearch($(this).find('#searchInput').val(),$yandexSearchModal);
  //     $(this).find('#searchInput').val('');
  // })
  // })
  var $sideNavBoxLinked = $('.js_sideNawBoxLinked');
  if ($sideNavBoxLinked.length > 0) {
    sideNavBoxLinked.init($sideNavBoxLinked); // init
  }

  $.ajax({
    url: "https://my.1sept.ru/api/1.0/user/name",
    accepts: {
        json: 'application/json'
    },
    dataType: 'json',
    xhrFields: {
      withCredentials: true
    },
  })
  .success(function(data) {
    renderUserName(data);
  }).error(function() {
    renderUserName();
  });

  $('.js_randomCarousel').each(function() {
    var $slides = $(this).find('li');
    var activeSlideNum = Math.floor(Math.random() * $slides.length);
    $($slides[activeSlideNum]).addClass('active');
    $slides.each(function() {
      if ($(this).data('priority') != '0') {
        var chanse = $(this).data('priority') * 1;
        var roll = Math.floor(Math.random() * 100);
        if (roll <= chanse) {
          $slides.removeClass('active');
          $(this).addClass('active');
          activeSlideNum = $(this).data('slide-to');
          $('#mainCarousel').carousel(activeSlideNum);
        }
      }
    });
    $('#mainCarousel').carousel(activeSlideNum);

  })
});

var makeUnselectable = function($target, $yandexSearchModal) {
  $target
    .addClass('unselectable') // All these attributes are inheritable
    .attr('unselectable', 'on') // For IE9 - This property is not inherited, needs to be placed onto everything
    .attr('draggable', 'false') // For moz and webkit, although Firefox 16 ignores this when -moz-user-select: none; is set, it's like these properties are mutually exclusive, seems to be a bug.
    .on('dragstart', function() {
      return false;
    }); // Needed since Firefox 16 seems to ingore the 'draggable' attribute we just applied above when '-moz-user-select: none' is applied to the CSS

  $target // Apply non-inheritable properties to the child elements
    .find('*')
    .attr('draggable', 'false')
    .attr('unselectable', 'on');
};

var sideNavBoxLinked = {
  //init function
  init: function($sideNavBoxLinked) {
    this.$sideNavBoxLinked = $sideNavBoxLinked;
    this.$links = this.$sideNavBoxLinked.find('ul li a');
    this.$boxs = $('.displayByNaw-container');
    this.readPageAdress();
    this.$links.on('click', function(event) {
      event.preventDefault();
      sideNavBoxLinked.hash = $(this).attr('href');

      sideNavBoxLinked.$targetLink = sideNavBoxLinked.$sideNavBoxLinked.find('[href="' + sideNavBoxLinked.hash + '"]')
      sideNavBoxLinked.$targetBox = sideNavBoxLinked.$boxs.filter(sideNavBoxLinked.hash)
      sideNavBoxLinked.changeActiveBlock(sideNavBoxLinked.$targetLink, sideNavBoxLinked.$targetBox);
    })
  },
  readPageAdress: function() {
    this.hash = location.hash;
    this.$targetLink = this.$sideNavBoxLinked.find('[href="' + this.hash + '"]')
    this.$targetBox = this.$boxs.filter(this.hash)
    if (this.$targetLink.length == 0 || this.$targetBox == 0) {
      return;
    } else {
      this.changeActiveBlock(this.$targetLink, this.$targetBox)
    }

  },
  //worker
  changeActiveBlock: function($targetLink, $targetBox) {

      this.$links.parent().removeClass('active');
      location.hash = $targetBox[0].id;

      this.$boxs.filter('.active').animate({
        'opacity': '0'
      }, 300, function() {
        $(this).removeClass('active');
        $targetBox.addClass('active');
        $targetBox.css("opacity", "0");
        $targetBox.animate({
          'opacity': '1'
        }, 300, function() {
          $("html, body").stop().animate({
            scrollTop: $targetBox.offset().top - 120
          }, 300);
        });
      })
      $targetLink.parent().addClass('active');

    }
    //validation??
    // isValid: function ($target){
    //     if($target.length==0){alert("Ошибка на странице. Сообщите пожалуйста администратору.") return false;} return true;
    // }
}

function renderUserName(jsonData) {
  var maxSymbols = 9;
  if (jsonData == undefined) {
    renderFailUserName();
    return;
  }
  var f, i, o;
  if (jsonData.hasOwnProperty('surname') && jsonData.hasOwnProperty('name') && jsonData.hasOwnProperty('patronymic')) {
    if (jsonData.surname) {
      f = jsonData.surname;
      if (f.length > maxSymbols) {
        f = f.slice(0, maxSymbols - 3) + '<span class="fio75">' + f.slice(maxSymbols - 3, maxSymbols - 2) + '</span><span class="fio50">' + f.slice(maxSymbols - 2, maxSymbols - 1) + '</span><span class="fio25">' + f.slice(maxSymbols - 1, maxSymbols) + '</span>'
      }
    } else {
      f = '';
    }

    if (jsonData.name) {
      i = jsonData.name[0] + '. ';
    } else {
      i = '';
    }

    if (jsonData.patronymic) {
      o = jsonData.patronymic[0] + '. ';
    } else {
      o = '';
    }

  } else {
    renderFailUserName();
    // console.error("json auth data is invalid");
    return;
  }
  // var summPhrase = i + o + f;

  $('#js_loginName').html(i + o + f);
  $('.js_loginStatus').removeClass('hidden');
  $('.js_login').addClass('hidden');
}

function renderFailUserName() {
  $('.js_loginStatus').addClass('hidden');
  $('.js_login').removeClass('hidden');
};
if (location.hash) {
  setTimeout(function() {
    window.scrollTo(0, 0);
  }, 1);
}

$(document).ready(function () {
    $('.news_title a').on('click', function (event) {
        id = $(event.currentTarget).attr('attr_id');
        $.post('/ajax/news_click/' + id, function ($data) {
            $data.rez;
        });
    });
});

// function beginSearch(searchString,$yandexSearchModal){
//     if (searchString == '') {
//         return;
//     }
//     $yandexSearchModal.modal();

//     var $modalBody = $yandexSearchModal.find(".modal-body");
//     var $findedTotal = $yandexSearchModal.find(".yandexFoundCount");
//     if ($modalBody.find('.yandexLoading').length == 0) {
//         $modalBody.append('<span class="yandexLoading la-square-loader la-dark"><div></div></span>');
//     }
//     $.get('/data/searchResponse.xml').success(function(data){
//         $modalBody.empty();
//         drawSearchResultFromYandex(data,$modalBody,$findedTotal)
//         // $modalBody.find('.yandexLoading').remove();
//     }).error(function(){
//         $modalBody.empty();
//         $modalBody.append('<h2>Сервис временно не доступен.</h2>');
//     });;
// }

// function drawSearchResultFromYandex(data,$targetModal,$targetTotalFind) {
//     var $data = $(data);
//     var resultCount = $data.find('found-human').html().replace(/\s\s+/g,'');
//     var resultPositions = $data.find('results group doc');
//     $targetTotalFind.html(resultCount);
//     var result = [];
//     resultPositions.each(function(){
//         // l("url: "+$(this).find('url').html().replace(/\s\s+/g,''));
//         // l("title: "+$(this).find('title').html().replace('<hlword>','<b>').replace('</hlword>','</b>').replace(/\s\s+/g,''));
//         // l("cont: "+$(this).find('passage').html().replace('<hlword>','<b>').replace('</hlword>','</b>').replace(/\s\s+/g,''));
//         result.push('<a class="findedResultItem" href="'+$(this).find('url').html().replace(/\s\s+/g,'')+'"><h1><span>'+$(this).find('title').html().replace('<hlword>','').replace('</hlword>','').replace(/\s\s+/g,'')+'<span></h1><p><span>'+$(this).find('passage').html().replace('<hlword>','<b>').replace('</hlword>','</b>')+'<span></p></a>');
//     });
//     $targetModal.append(result);
// }
